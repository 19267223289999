/* eslint-disable indent */
const useGA = ({ autoLoad = true }: { autoLoad?: boolean }) => {
	const {
		public: { gaId }
	} = useRuntimeConfig();
	useHead(
		{
			script:
				!gaId || !autoLoad
					? []
					: [
							{
								innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gaId}');`
							}
						]
		},
		{ mode: "client" }
	);
	onMounted(() => {
		if (!window.dataLayer) {
			window.dataLayer = [];
		}
	});

	const insertGA = () => {
		const SCRIPT_ID = "GA";
		const GA_SCRIPT = document.getElementById(SCRIPT_ID);
		if (GA_SCRIPT) {
			return;
		}

		const newScript = document.createElement("script");
		newScript.id = SCRIPT_ID;

		newScript.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gaId}');`;
		document.body.appendChild(newScript);
	};

	return { insertGA };
};

export default useGA;
